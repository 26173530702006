import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Masonry from "~/components/Grid/Masonry"
import News from "~/components/News"
import useScrollToTop from "~/hooks/useScrollToTop"
import Slider from "~/components/Slider"
import decode from "~/functions/decode"
import SliderSlick from "react-slick";
import "./influencer.css"

// @ts-ignore
import Facebook from "~/images/Icons/social/facebook.svg"
// @ts-ignore
import Instagram from "~/images/Icons/social/instagram.svg"
// @ts-ignore
import IconAttachment from "~/images/Icons/icon-attachment.svg"
import Box from "~/components/Box"
import Card from "~/components/Card"
import Picture from "~/components/Picture";
import embedVideoUrl from "~/functions/embedVideoUrl";
import { I18nContext } from "~/components/I18n";

export const query = graphql`query ($id: String!) {
  site {
    siteMetadata {
      title
      author
      siteUrl
    }
  }
  wpInfluencer(id: {eq: $id}) {
    id
    uri
    title
    excerpt
    facebookUrl
    instagramUrl
    clipping {
      title
      caption
      altText
      sourceUrl
      srcSet
      sizes
      mimeType
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 900, height: 1200, quality: 92, layout: CONSTRAINED)
        }
      }
    }
    mediakit {
      pdfFile {
        id
        mediaItemUrl
      }
    }
    videography {
      embedUrl
    }
    gallery {
      title
      caption
      altText
      sourceUrl
      srcSet
      sizes
      mimeType
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 900, height: 1200, quality: 92, layout: CONSTRAINED)
        }
      }
    }
    portfolio {
      portfolio {
        ... on WpPost {
          id
          uri
          date
          title
          excerpt
          categories {
            nodes {
              id
            }
          }
          featuredImage {
            node {
              title
              caption
              altText
              sourceUrl
              srcSet
              sizes
              mimeType
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 900, height: 1200, quality: 92, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    featuredImage {
      node {
        title
        caption
        altText
        sourceUrl
        srcSet
        sizes
        mimeType
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 650, height: 650, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`

export default ({
  data: {
    wpInfluencer: {
      id, uri, title, excerpt, facebookUrl, instagramUrl, gallery, clipping, portfolio, featuredImage, mediakit, videography,
    },
    site: {
      siteMetadata: {
        title: siteTitle,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpInfluencer: {
    id: string
    uri: string
    date: string
    title: string
    content: string
    excerpt: string
    facebookUrl: string
    instagramUrl: string
    clipping: {
      title: string
      caption: string
      altText: string
      sourceUrl: string
      srcSet: string
      sizes: string
      mimeType: string
      localFile: {
        publicURL: string
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
    mediakit: {
      pdfFile: {
        id: string
        mediaItemUrl: string
      }
    }
    videography: {
      embedUrl: string
    }
    gallery: {
      title: string
      caption: string
      altText: string
      sourceUrl: string
      srcSet: string
      sizes: string
      mimeType: string
      localFile: {
        publicURL: string
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
    portfolio: {
      portfolio: {
        id: string
        uri: string
        title: string
        excerpt: string
        date: string
        categories: {
          nodes: {
            id: string
          }[]
        }
        featuredImage: {
          node: {
            title: string
            caption: string
            altText: string
            sourceUrl: string
            srcSet: string
            sizes: string
            mimeType: string
            localFile: {
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData
              }
            }
          }
        }
      }[]
    }
    featuredImage: {
      node: {
        title: string
        caption: string
        altText: string
        sourceUrl: string
        srcSet: string
        sizes: string
        mimeType: string
        localFile: {
          publicURL: string
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      }
    }
  }
}>) => {
  useScrollToTop();

  const { locale } = useContext(I18nContext)

  return <>
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: locale,
        dir: "ltr",
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      <title>{title}</title>
      <link rel="author" href="/humans.txt" />

      <meta name="description" content={excerpt} />
      <meta name="image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />

      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:description" content={excerpt} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={excerpt} />
      <meta name="twitter:image" content={`${siteUrl}${featuredImage?.node?.localFile.publicURL}`} />


    </Helmet>

    <header>
      <p className="is-size-1 is-uppercase is-font-family-serif">People</p>

      <nav className="is-uppercase has-half-margin-top">
        <Link to="/people"><b><i>People</i></b></Link> // <Link to={uri}>{title}</Link>
      </nav>
    </header>

    <article id="site__influencer-article" className="is-post has-margin-bottom">
      <div id="site__influencer-gallery">
        {(gallery && gallery.length > 0) && <Slider>
          {gallery
          .map(({srcSet, sourceUrl, sizes, mimeType, localFile}) =>
          localFile.childImageSharp?.gatsbyImageData
            ? (
              <GatsbyImage
                key={localFile.publicURL}
                image={localFile?.childImageSharp?.gatsbyImageData}
                alt=""
              />
          ) : (
            <Picture src={sourceUrl} alt="" sources={[
              {srcSet, sizes, mimeType}
            ]} />
          )
        )}
        </Slider>}
        {(!gallery || gallery.length === 0) && (
          featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
          ? (
            <GatsbyImage
              image={featuredImage.node.localFile.childImageSharp?.gatsbyImageData}
              alt=""
            />
          ) : featuredImage?.node?.sourceUrl ? (
            <Picture src={featuredImage?.node?.sourceUrl} alt="" sources={[
              featuredImage.node
            ]} />
          ) : <div />
        )}
      </div>

      <header>
        <h1>{title}</h1>
      </header>

      <div className="article-content prose" dangerouslySetInnerHTML={{ __html: excerpt }} />

      <section id="site__influencer-social">
        <div className="social-links">
          {instagramUrl && (
            <a href={instagramUrl} target="_blank" rel="noreferer nofollower" role="button" className="is-smaller is-square is-borderless has-half-margin-right">
              <Instagram width="24" height="24" />
            </a>
          )}
          {facebookUrl && <a href={facebookUrl} target="_blank" rel="noreferer nofollower" role="button" className="is-smaller is-square is-borderless has-half-margin-right">
            <Facebook width="24" height="24" />
          </a>}
          {mediakit && mediakit.pdfFile && <a href={mediakit.pdfFile.mediaItemUrl} target="_blank" rel="noreferer nofollower" role="button" className="is-smaller is-borderless has-half-margin-right has-padding-right" download>
            <IconAttachment width="24" height="24" /> MediaKit&nbsp;
          </a>}
        </div>
      </section>

      {(clipping && clipping.length > 0) && <section id="site__influencer-campaigns">
        <h2 className="is-uppercase">Campanhas &amp; Clipping</h2>
        <div id="site__influencer-gallery">
        <SliderSlick {...{
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }}>
          {clipping
          .map(({ title, altText, caption, sourceUrl, srcSet, sizes, mimeType, localFile }, index) => (
            <Box
              key={index}
              className="card-influencer-clipping__container"
              background={localFile.childImageSharp?.gatsbyImageData ? (
                <GatsbyImage image={localFile.childImageSharp?.gatsbyImageData} alt="" />
              ) : (
                <Picture src={sourceUrl} alt="" sources={[
                  {srcSet, sizes, mimeType}
                ]} />
              )}
            >
              <Card
                className="is-align-items-bottom"
              >
                <article className="has-text-shadow">
                  <h4 className="has-no-margin-bottom">{decode(title || '')}</h4>
                  <p>{decode(altText ||caption || '')}</p>
                </article>
              </Card>
            </Box>
          )
        )}
        </SliderSlick>
        </div>
      </section>}

      {videography?.embedUrl && <section id="site__influencer-videography">
        <div className="is-responsive-16-9">
          <iframe
            src={embedVideoUrl(videography.embedUrl)}
            allowFullScreen
          />
        </div>
      </section>}

      {portfolio && portfolio.portfolio && portfolio.portfolio.length > 0 && <section id="site__influencer-portfolio">
        <h2>News // Portfolio</h2>
        <div id="site__influencer-campaigns-grid">
        <section>
          <Masonry className="has-margin-top news-grid">
            {portfolio?.portfolio?.filter(({ date }) => new Date(date) <= new Date())
            .map(({
              id, uri, date, title, excerpt, featuredImage
            }) => {
              return (
                <News
                  key={id}
                  id={id}
                  uri={uri}
                  title={title}
                  subtitle={excerpt}
                  date={new Date(date)}
                  display={true}
                  featuredImage={featuredImage}
                />
              )
            })}
          </Masonry>
        </section>
        </div>
      </section>}

    </article>
  </>;
}
